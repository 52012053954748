import React from "react"
import { Link } from "gatsby"
import "./LinkSwitch.scss"

const LinkSwitch = ({ title, path }) => {
  return (
    <div className="link-item">
      <Link state={{ dropdownLink: true }} to={path} className="link-switch">
        {title}
      </Link>
    </div>
  )
}

export default LinkSwitch
