const LOCALE = {
  LT: {
    lt_en: "lt_en",
    lt_lt: "lt_lt",
    cy_en: "cy_en",
  },
  BY: {
    BY: "by",
    AM: "am",
    EN_AM: "en_am",
    RU: "ru",
  },
}

export const CLIENT_INSTRUCTION_STICKERS_LANGUAGE = {
  lt_en: "lt_en",
  lt_lt: "lt_lt",
  cy_en: "cy_en",
  by: "by",
  am: "am",
  en_am: "en_am",
  ru: "ru"
}

export default LOCALE
