import React from "react"
import Logo from "src/components/Logo/Logo"
import "./Footer.scss"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"
import cn from "classnames"
import { brandConstansByLanguage } from "../../domain_utils"
import { BRAND } from "../../brands"

const Footer = ({ country, hotLine, links, social, baseUrl, emailLink }) => {
  const { t } = useTranslation()

  const brand = brandConstansByLanguage(country)
  const legalAddress = brand.legalAddress

  function getBuildNumber() {
    const brandPrefix = BRAND
    const buildPostfix = process.env.GATSBY_BITBUCKET_BUILD_NUMBER || "local"
    return `${brandPrefix}.${buildPostfix}`
  }

  return (
    <footer className="footer">
      <Logo type="white" baseUrl={baseUrl} country={country} />

      <div className={cn("contacts")}>
        <a href={hotLine.tel}>{hotLine.title}</a>
        {hotLine.whatsapp ? (
          <a
            aria-label="Chat on WhatsApp"
            href={"https://wa.me/" + hotLine.whatsapp}
          >
            {" "}
            <img
              alt="Chat on WhatsApp"
              src="WhatsAppButtonGreenLarge.svg"
            />{" "}
          </a>
        ) : (
          ""
        )}
        {hotLine.telephone ? (
          <a href={hotLine.telephone}>{hotLine.telephoneTitle}</a>
        ) : (
          ""
        )}
        <a href={emailLink.href} target="_blank" rel="noopener noreferrer">
          {emailLink.title}
        </a>
      </div>
      <div className="social">
        {social.map((social) => {
          const { icon, href } = social
          return (
            <a
              key={href}
              target="_blank"
              rel="noreferrer nofollow"
              href={href || "/"}
              style={{ backgroundImage: `url('${icon}')`, backgroundSize: `32px 32px` }}
            >

            </a>
          )
        })}
      </div>

      <ul className="links">
        {links.map((link) => {
          const { text, href } = link
          return (
            <li key={text}>
              <Link state={{ dropdownLink: true }} to={href || "/"}>
                {t(text)}
              </Link>
            </li>
          )
        })}
      </ul>

      <p>{t("footerInfo.registration", { legalAddress })}</p>
      <span className="copy">{t("footerInfo.rights")}</span>
      <span className="copy">Build: {getBuildNumber()}</span>
    </footer>
  )
}

export default Footer
